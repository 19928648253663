<template>
  <card-component title="Profil Bilgileri Güncelleme" icon="account-circle">
    <form @submit.prevent="submit">
      <b-field horizontal :label="$t('fullname')">
        <b-input
          v-model.trim="form.name"
          name="name"
          placeholder="Lütfen adınızı ve soyadınızı giriniz."
          validation-message="Lüten bu alana adınızı ve soyadınızı giriniz."
          required
        />
      </b-field>
      <b-field horizontal :label="$t('username')">
        <b-input
          :value="username"
          disabled
        />
      </b-field>
      <b-field horizontal :label="$t('phoneNumber')">
        <VuePhoneNumberInput
          v-model="form.phone"
          :default-country-code="phoneCountryCode"
          required
          @update="updatePhone"
        />
      </b-field>
      <b-field horizontal :label="$t('emailAddress')">
        <b-input
          v-model.trim="form.email"
          name="email"
          type="email"
          required
        />
      </b-field>
      <b-field horizontal :label="$t('department')">
        <b-input :value="department" disabled />
      </b-field>
      <b-field horizontal :label="$t('role')">
        <b-input :value="role" disabled />
      </b-field>
      <hr>
      <b-field horizontal>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{'is-loading': isLoading}">
            {{ $t('save') }}
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import { mapState } from 'vuex'
import CardComponent from '@/components/CardComponent'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VuePhoneNumberInput from 'vue-phone-number-input'
import parsePhoneNumber from 'libphonenumber-js'

export default {
  name: 'ProfileUpdateForm',
  components: {
    CardComponent,
    VuePhoneNumberInput,
  },
  data () {
    return {
      isLoading: false,
      phoneCountryCode: 'TR',
      form: {
        name: null,
        email: null,
        phone: null,
      },
    }
  },
  computed: {
    ...mapState([
      'user',
    ]),
    username () {
      return this.user.attributes?.username
    },
    department () {
      return this.user.attributes?.department_name
    },
    role () {
      return this.user.attributes?.role
    },
  },
  watch: {
    user (newValue) {
      this.form.name = newValue.attributes.name
      this.form.email = newValue.attributes.email

      const phoneNumber = parsePhoneNumber(newValue.attributes.phone)
      this.form.phone = phoneNumber.formatNational()
      this.phoneCountryCode = phoneNumber.country
    },
  },
  mounted () {
    if (this.user.attributes) {
      this.form.name = this.user.attributes.name
      this.form.email = this.user.attributes.email

      const phoneNumber = parsePhoneNumber(this.user.attributes.phone)
      this.form.phone = phoneNumber.formatNational()
      this.phoneCountryCode = phoneNumber.country
    }
  },
  methods: {
    updatePhone (data) {
      this.phoneCountryCode = data.countryCode
      this.form.phone = data.e164
    },
    submit () {
      if (!this.form.name || !this.form.email || !this.form.phone) {
        this.$buefy.snackbar.open({
          message: 'Lütfen bilgilerinizi eksiksiz doldurun.',
          type: 'is-danger',
          queue: false,
        })

        return
      }

      this.isLoading = true

      this.$http
        .put('auth/update', {...this.form, phone: parsePhoneNumber(this.form.phone, this.phoneCountryCode).number})
        .then(({ data }) => {
          this.$store.commit('set_user', data.data)
          this.$buefy.snackbar.open({
            message: 'Profil bilgileriniz güncellendi.',
            queue: false,
          })
        })
        .catch(({ response }) => {
          const message = this.$_.flattenDeep(this.$_.values(response.data.errors)).join('<br>')
          this.$buefy.snackbar.open({
            message,
            type: 'is-danger',
            queue: false,
          })
        })
        .finally(() => this.isLoading = false)
    },
  }
}
</script>
