<script>
import { mapGetters } from 'vuex'
import HeroBar from '@/components/HeroBar'
import ProfileUpdateForm from '@/components/ProfileUpdateForm'
import PasswordUpdateForm from '@/components/PasswordUpdateForm'
import UserAddresses from '@/components/UserAddresses'

export default {
  name: 'Profile',
  components: {
    HeroBar,
    ProfileUpdateForm,
    PasswordUpdateForm,
    UserAddresses,
  },
  data () {
    return {
      addresses: [],
    }
  },
  computed: {
    ...mapGetters([
      'authUser',
    ]),
  },
  watch: {
    authUser (newValue) {
      if (newValue.id) {
        this.$http.get(`/users/${newValue.id}/addresses`).then(({ data }) => this.addresses = data.data)
      }
    },
  },
  created () {
    if (this.authUser.id) {
      this.$http.get(`/users/${this.authUser.id}/addresses`).then(({ data }) => this.addresses = data.data)
    }
  },
}
</script>

<template>
  <div>
    <HeroBar>
      {{ $t('myProfile') }}
      <router-link slot="right" :to="{ name: 'board' }" class="button">
        {{ $t('board') }}
      </router-link>
    </HeroBar>
    <section class="section is-main-section">
      <ProfileUpdateForm />
      <PasswordUpdateForm class="mt-4" />
      <UserAddresses :addresses="addresses" class="mt-4" />
    </section>
  </div>
</template>
