<template>
  <card-component title="Şifre Güncelleme" icon="lock">
    <form @submit.prevent="submit">
      <b-field horizontal label="Mevcut Şifre">
        <b-input
          v-model="form.password_current"
          name="password_current"
          type="password"
          placeholder="Lütfen mevcut şifrenizi giriniz."
          validation-message="Lüten bu alana mevcut şifrenizi giriniz."
          required
        />
      </b-field>
      <hr>
      <b-field horizontal label="Yeni Şifre">
        <b-input
          v-model="form.password"
          name="password"
          type="password"
          placeholder="Lütfen yeni şifrenizi giriniz."
          validation-message="Lüten bu alana yeni şifrenizi giriniz."
          required
        />
      </b-field>
      <b-field horizontal label="Yeni Şifre (Tekrar)">
        <b-input
          v-model="form.password_confirmation"
          name="password_confirmation"
          type="password"
          placeholder="Lütfen yeni şifrenizi giriniz."
          validation-message="Lüten bu alana yeni şifrenizi giriniz."
          required
        />
      </b-field>
      <hr>
      <b-field horizontal>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{'is-loading': isLoading}">
            {{ $t('save') }}
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'PasswordUpdateForm',
  components: {
    CardComponent,
  },
  data () {
    return {
      isLoading: false,
      form: {
        password_current: null,
        password: null,
        password_confirmation: null,
      },
    }
  },
  methods: {
    submit () {
      if (!this.form.password_current || !this.form.password || !this.form.password_confirmation) {
        this.$buefy.snackbar.open({
          message: 'Lütfen bilgilerinizi eksiksiz doldurun.',
          type: 'is-danger',
          queue: false,
        })

        return
      }

      this.isLoading = true

      this.$http
        .put('auth/update_password', this.form)
        .then(() => {
          this.form.password_current = null
          this.form.password = null
          this.form.password_confirmation = null

          this.$buefy.snackbar.open({
            message: 'Şifreniz güncellendi.',
            queue: false,
          })
        })
        .catch(({ response }) => {
          const message = this.$_.flattenDeep(this.$_.values(response.data.errors)).join('<br>')
          this.$buefy.snackbar.open({
            message,
            type: 'is-danger',
            queue: false,
          })
        })
        .finally(() => this.isLoading = false)
    }
  }
}
</script>
